import { formatDate } from '@vueuse/core'
import { type DateTime } from 'luxon'

import { type User } from './api'

/**
 * @returns {string} "dd.mm.yyyy"
 */
export function formatDateReadable(date: Date) {
  return formatDate(date, 'DD.MM.YYYY')
}

export function formatDateTimeReadable(date: Date) {
  return formatDate(date, 'DD.MM.YYYY hh:mm')
}

export function formatDateApi(date: DateTime | undefined) {
  if (!date) return date
  return date.toUTC().toISODate()!
}

export function formatDateTimeApi(date: DateTime | undefined) {
  if (!date) return date
  return date.toUTC().toISO()!
}

export function formatIpApi<T extends string | undefined>(ip: T): T {
  if (!ip) return ip
  return ip.replace(/\/\d*$/, '0') as T
}

export function isProduction(): boolean {
  return window.location.hostname.endsWith('ibh.de')
}

export function ensureTrailingSlash(str: string) {
  return str.endsWith('/') ? str : `${str}/`
}

export function isSameRoute(routeA: string, routeB: string) {
  return ensureTrailingSlash(routeA) === ensureTrailingSlash(routeB)
}

export function isChildRoute(childRoute: string, parentRoute: string) {
  return ensureTrailingSlash(childRoute).startsWith(ensureTrailingSlash(parentRoute))
}

function optionalFirst(text: string) {
  if (!text.length || text.length === 0) return undefined
  return text[0]
}
export function firstLettersOf(user: User) {
  return `${optionalFirst(user.first_name) ?? ''}${optionalFirst(user.last_name) ?? ''}`
}
